<template>
    <div>
        <div 
            v-if="loading" 
            class="flex justify-center">
            <a-spin />
        </div>
        <template v-if="tab">
            <component 
                :is="headerActions" 
                :tab="tab"
                :task="task"
                :code="code"
                v-model="visible" />
            <component 
                :is="checkModal" 
                :tab="tab"
                :task="task"
                :code="code"
                v-model="visible" />
            <component 
                :is="listWidget"
                v-if="getTab"
                :task="task"
                :code="code" />
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import List from './UniversalTabWidgets/List.vue'
export default {
    components: {
        List
    },
    props: {
        task: {
            type: Object,
            default: () => null
        },
        code: {
            type: [String, Number],
            required: true
        }
    },
    computed: {
        ...mapGetters({
            getTab: 'task/getTab'
        }),
        tab() {
            return this.getTab(this.task.id, this.code)
        },
        headerActions() {
            if(this.tab?.headerButtons && Object.keys(this.tab.headerButtons).length)
                return () => import('./UniversalTabWidgets/HeaderActions.vue')
            else
                return null
        },
        checkModal() {
            if(this.tab?.modal && Object.keys(this.tab.modal).length && this.tab?.formInfo)
                return () => import('./UniversalTabWidgets/FormModal.vue')
            else
                return null
        },
        isMobile() {
            return this.$store.state.isMobile
        },
        listWidget() {
            if(this.isMobile)
                return () => import('./UniversalTabWidgets/ListMobile.vue')
            return () => import('./UniversalTabWidgets/List.vue')
        }
    },
    data() {
        return {
            loading: false,
            visible: false
        }
    },
    created() {
        this.getInfo()
    },
    methods: {
        ...mapActions({
            getTabInfo: 'task/getTabInfo'
        }),
        async getInfo() {
            try {
                this.loading = true
                this.getTabInfo({
                    part: this.code,
                    task: this.task
                })
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>